import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { Section } from '../../styled-components';
import FormProperty from '../forms/properties'

const Title = styled.h1`
  //width: 50%;
  text-align: center;
  margin-bottom: 4rem; 
`

const MainCont = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`

export default ()=> {
  return(
    <MainCont 
      first
      height="50vh"
    >
      <Container>
     
        <Title>
          <br/>
          <br/>
          <br/>
          
          Encuentra tu nuevo hogar aquí
        </Title>
        <FormProperty id="formSearch" withFilters />
      </Container>
    </MainCont>
  )
}